import React from "react";
import { Route } from "react-router-dom";
import Coupon from "../components/Dashboard/DashboardItemHome/Coupon/Coupon";
import CouppnNew from "../components/Dashboard/DashboardItemHome/CouppnNew/CouppnNew";
import EditCoupon from "../components/Dashboard/DashboardItemHome/EditCoupon/EditCoupon";
import CourseHome from "../components/StudentPages/AllCourses/CourseHome";
import AuthorOverview from "../components/StudentPages/AuthorOverview/AuthorOverview";
import BeforeLoginCart from "../components/StudentPages/BeforeLoginCart/BeforeLoginCart";
import CartPage from "../components/StudentPages/CartPage/CartPage";
import ChackoutPage from "../components/StudentPages/ChackoutPage/ChackoutPage";
import CourseHomeCategory from "../components/StudentPages/CoursesStudent/CourseHomeCategory";
import CourseDetailsHome from "../components/StudentPages/CourseWareDetailsPage/CourseDetailsHome";
import CourseWareDetailsPageAfterLogin from "../components/StudentPages/CourseWareDetailsPageAfterLogin/CourseWareDetailsPageAfterLogin";
import EmptyCart from "../components/StudentPages/EmptyCart/EmptyCart";
import GiftReward from "../components/StudentPages/GiftReward/GiftReward";
import StudentCategoryHome from "../components/StudentPages/StudentCategory/StudentCategoryHome";
import StudentProfileEdit from "../components/StudentPages/StudentProfileEdit/StudentProfileEdit";
import StudentProfileView from "../components/StudentPages/StudentProfileView/StudentProfileView";
import TeacherCourseAdd from "../components/StudentPages/TeacherCourseAdd/TeacherCourseAdd";
import TeacherProfileEdit from "../components/StudentPages/TeacherProfileEdit/TeacherProfileEdit";
import TeacherProfileView from "../components/StudentPages/TeacherProfileView/TeacherProfileView";
import AboutUs from "../pages/AboutUs/AboutUs";
import PrivecyPolicy from "../pages/PrivecyPolicy/PrivecyPolicy";
import ReturnPolicy from "../pages/ReturnPolicy/ReturnPolicy";
import TermAndConditions from "../pages/TermAndConditions/TermAndConditions";
import Settings from "./../components/Dashboard/DashboardItemHome/Settings/Settings";
import AuthorVIew from "./../components/StudentPages/AuthorProfile/AuthorVIew";
import CheckoutPageGift from "./../components/StudentPages/ChackOutPageGift/ChackoutPageGift";
const CategoryRoute = () => {
  return (
    <div>
      <Route exact path="/student-category/:academicId">
        <StudentCategoryHome />
      </Route>
      <Route exact path="/courses">
        <CourseHomeCategory />
      </Route>
      <Route exact path="/student-courses">
        <CourseHome />
      </Route>
      <Route exact path="/course-details-page/:courseId">
        <CourseDetailsHome />
      </Route>
      <Route exact path="/student-profile-page">
        <CourseWareDetailsPageAfterLogin />
      </Route>
      <Route exact path="/student-profile-view">
        <StudentProfileView />
      </Route>
      <Route exact path="/edit-student-profile">
        <StudentProfileEdit />
      </Route>
      <Route exact path="/teacher-profile-view">
        <TeacherProfileView />
      </Route>
      <Route exact path="/edit-teacher-profile">
        <TeacherProfileEdit />
      </Route>
      <Route exact path="/cart-page">
        <CartPage />
      </Route>
      <Route exact path="/checkout">
        <ChackoutPage />
      </Route>
      <Route exact path="/checkout-gift">
        <CheckoutPageGift />
      </Route>
      <Route exact path="/login-before-cart">
        <BeforeLoginCart />
      </Route>
      <Route exact path="/empty-cart">
        <EmptyCart />
      </Route>
      <Route exact path="/:courseId/teacher-add-course-page">
        <TeacherCourseAdd />
      </Route>
      {/* //author profile  */}
      <Route exact path="/:authorId/author-profile">
        <AuthorVIew />
      </Route>
      <Route exact path="/author-overview">
        <AuthorOverview />
      </Route>
      <Route exact path="/gift-reward-page">
        <GiftReward />
      </Route>
      <Route exact path="/coupon">
        <Coupon />
      </Route>
      <Route exact path="/coupon-new">
        <CouppnNew />
      </Route>
      <Route exact path="/coupon-edit">
        <EditCoupon />
      </Route>{" "}
      <Route exact path="/privecy-policy">
        <PrivecyPolicy />
      </Route>{" "}
      <Route exact path="/term-and-condition">
        <TermAndConditions />
      </Route>{" "}
      <Route exact path="/refund-return-policy">
        <ReturnPolicy />
      </Route>{" "}
      <Route exact path="/about-us">
        <AboutUs />
      </Route>
      <Route exact path="/settings-s">
        <Settings />
      </Route>
    </div>
  );
};

export default CategoryRoute;
