import _clone from "lodash/clone";
import _escapeRegExp from "lodash/escapeRegExp";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { postData } from "../../../../../helpers/backend_helper";
import { fillGaps, mcq, situationQuestion } from "../Question";
import QuestionComponent from "./QuestionComponent";
function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}
const fillGap = (value) => {
  let displayText = _clone(value);
  const tags = value?.match(/\$(.*?)\$+/gi) || [];
  if (tags?.length) {
    tags.forEach((myTag, idx) => {
      displayText = displayText?.replace(
        new RegExp(_escapeRegExp(myTag), "gi"),
        `<span class="fill-in-the-gaps"></span>`
      );
    });
  }
  return displayText;
};

const Questions = ({
  answer,
  handleTakeAnswer,
  setReRender,
  questions,
  setLafargePopup,
}) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { role, token } = useSelector((state) => ({
    token: state.Login.token,
    role: state.Login.userrole,
  }));

  console.log("questions id", questions._id);
  const [newQuestions, setNewQuestions] = useState([]);
  const [storeNumber, setStoreNumber] = useState({});
  const [questionSerial, setQuestionSerial] = useState({});
  console.log(`line 8 ~ MCQType ~ storeNumber`, storeNumber, newQuestions);

  useEffect(() => {
    const randomNumber = getRandomArbitrary(0, 7);
    setNewQuestions((prev) => [...prev, mcq[randomNumber]]);
    setStoreNumber((prev) => ({
      ...prev,
      [randomNumber]: 1,
    }));
    setQuestionSerial((prev) => ({
      ...prev,
      [newQuestions?.length + 1]: randomNumber,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = () => {
    let check = false;
    if (newQuestions?.length < 5) {
      while (!check) {
        const randomNumber = getRandomArbitrary(0, 7);
        if (storeNumber[randomNumber] === undefined) {
          check = true;
          setStoreNumber((prev) => ({
            ...prev,
            [randomNumber]: 1,
          }));
          setNewQuestions((prev) => [...prev, mcq[randomNumber]]);
          setQuestionSerial((prev) => ({
            ...prev,
            [newQuestions?.length + 1]: randomNumber,
          }));
        }
      }
    } else if (newQuestions?.length < 8) {
      while (!check) {
        const randomNumber = getRandomArbitrary(0, 5);
        if (storeNumber[randomNumber + 5] === undefined) {
          check = true;
          setStoreNumber((prev) => ({
            ...prev,
            [randomNumber + 5]: 1,
          }));
          setNewQuestions((prev) => [...prev, fillGaps[randomNumber]]);
          setQuestionSerial((prev) => ({
            ...prev,
            [newQuestions?.length + 1]: randomNumber,
          }));
        }
      }
    } else if (newQuestions?.length === 8) {
      if (role === "kam") {
        setQuestionSerial((prev) => ({
          ...prev,
          9: 0,
        }));
        setNewQuestions((prev) => [...prev, situationQuestion?.[1]]);
      } else {
        setQuestionSerial((prev) => ({
          ...prev,
          9: 1,
        }));
        setNewQuestions((prev) => [...prev, situationQuestion?.[0]]);
      }
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const url = `/user/go-next-module/${questions?._id}`;
      const response = await postData(url, {}, token);
      if (response) {
        setReRender((prev) => !prev);
        setOpen(false);
        setSubmitting(false);
        setLafargePopup(true);
      }
    } catch (err) {
      const error = err.response?.data?.error;
      console.log(error);
      setOpen(false);
      setSubmitting(false);
    }
  };
  const history = useHistory();
  return (
    <React.Fragment>
      <Modal centered isOpen={open} toggle={() => setOpen(!open)}>
        <div className="modal-inner-design">
          <ModalBody className="p-4">
            <p>
              You can't come back to this section. Do you want to go to the next
              section ?
            </p>
            <div className="d-flex justify-content-end lafarge-training-footer-button">
              <button className="btn " onClick={() => setOpen(!open)}>
                Cancel
              </button>
              <button
                className="btn "
                disabled={submitting}
                onClick={handleSubmit}
              >
                {submitting ? "Submitting..." : "Next"}
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>
      {newQuestions?.map((item, index) => (
        <div className="lafarge-exercise-single-item mb-5" key={index}>
          <div className="lafarge-exercise-single-item-inner">
            <Row>
              <Col md={"6 my-auto"}>
                <div className="lafarge-exercise-single-item-inner-left">
                  <h6>{index + 1} / 9</h6>
                  {item?.type === "fillGap" ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: fillGap(item?.question),
                      }}
                    ></p>
                  ) : (
                    <p>
                      {item?.question}
                      {item?.type === "situation" && (
                        <React.Fragment>
                          <br />
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            (Maximum 500 characters)
                          </span>
                        </React.Fragment>
                      )}
                    </p>
                  )}
                </div>
              </Col>
              <Col md={6}>
                <QuestionComponent
                  index={index}
                  answer={answer}
                  handleTakeAnswer={handleTakeAnswer}
                  item={item}
                  questionSerial={questionSerial}
                />
              </Col>
            </Row>
          </div>
          <div className="lafarge-ques-submit-button">
            {answer?.[index + 1]?.givenAnswer &&
            newQuestions?.length - 1 !== index ? (
              <button className="lafarge-ques-submit-button-inner">
                Modify
              </button>
            ) : newQuestions?.length === 9 ? (
              <div className="lafarge-ques-next-section-wrap-button">
                <h6>Submit and proceed to next section</h6>
                <button
                  className="lafarge-ques-submit-button-inner"
                  onClick={() => {
                    handleSubmit();
                    history.push(
                      `/lafarge-guidlines/${questions._id}?questionID=${questions._id}`
                    );
                  }}
                >
                  Next Section
                </button>
              </div>
            ) : (
              <button
                className="lafarge-ques-submit-button-inner"
                disabled={answer?.[index + 1]?.givenAnswer === ""}
                onClick={() => {
                  if (answer?.[index + 1]?.givenAnswer) {
                    handleNext();
                  }
                }}
              >
                Next
              </button>
            )}
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default Questions;
