import React from "react";
import { Form } from "react-bootstrap";
import inputBg from "./img/file.svg";
const FormatEditBody = ({
  categoryName,
  handleSetFormatData,
  image,
  updatedPhoto,
  hanldeUploadImage,
  history,
  handleUpdateFormat,
}) => {
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Edit Format</h3>
            <p>
              Course list<i className="bx bx-chevron-right"></i> format{" "}
              <i className="bx bx-chevron-right"></i>
              <span> Edit Format</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="package-main-content-list kjhgfwertyuiutfc">
        <div className="add-new-format-area-wrap">
          <div className="delete-btn-rfvbjuyghbjuyhnm">
            <button>
              <i className="bx bx-trash-alt"></i>
            </button>
          </div>
          <div className="add-new-format-inner-item-wrap">
            <h4>Format</h4>
            <Form className="format-upload-form-main-area-zkjdh">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Formate name</Form.Label>
                <Form.Control
                  type="Text"
                  value={categoryName.name}
                  onChange={(e) => handleSetFormatData(e)}
                  placeholder="Tag name"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Upload user image</Form.Label>
                <img
                  className="autor"
                  src={`${process.env.REACT_APP_IMAGE_URL}${
                    updatedPhoto ? updatedPhoto : image?.image
                  }`}
                  height={"50px"}
                />
                :
                <div className="button-area-input-data-wrap">
                  <label
                    for="file-upload"
                    // style={{ backgroundImage: `url(${inputBg})` }}
                  >
                    <img src={inputBg} alt="" />
                    Upload Your Data
                  </label>
                  <input
                    type="file"
                    id="file-upload"
                    onChange={(e) => hanldeUploadImage(e)}
                  />
                </div>
              </Form.Group>
              <div className="">
                <div className="create-new-format-btn-area-wrap">
                  <button onClick={(e) => handleUpdateFormat(e)}>Update</button>
                  <button onClick={(e) => history.push("/format-list")}>
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormatEditBody;
