import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { createCategory } from "../../../../store/actions";
const NewFormatBody = () => {
  const [categoryName, setCategoryName] = useState("");
  const [file, setFile] = useState("");
  let history = useHistory();
  let dispatch = useDispatch();
  const { token, loading } = useSelector((state) => ({
    token: state.Login.token,
    loading: state.AdminAuthorReducer.loading,
  }));
  const handleClick = (e) => {
    e.preventDefault();

    let formData = new FormData();
    if (!categoryName || !file) {
      toast.error("Please filled all the fill with necessary data");
    } else {
      formData.append("categoryName", categoryName);
      formData.append("photo", file);

      dispatch(createCategory(token, formData, history));
    }
  };
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>New Format</h3>
            <p>
              Course <i class="bx bx-chevron-right"></i> Format{" "}
              <i class="bx bx-chevron-right"></i>
              <span> New Format</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="package-main-content-list kjhgfwertyuiutfc">
        <div className="add-new-format-area-wrap">
          <div className="add-new-format-inner-item-wrap">
            <h4>Create New Format</h4>
            <Form className="format-upload-form-main-area-zkjdh">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Format name</Form.Label>
                <Form.Control
                  type="Text"
                  value={categoryName}
                  placeholder="Tag name"
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Upload user image</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Tag name"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Form.Group>
              {/* <Form.Group>
                <Form.Label>Upload user image</Form.Label>
                <div className="button-area-input-data-wrap">
                  <label
                    for="file-upload"
                    style={{ backgroundImage: `url(${inputBg})` }}
                  >
                    <img src={inputBg} alt="" />
                    Upload Your Data
                  </label>
                  <input
                    type="file"
                    id="file-upload"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
              </Form.Group> */}
              {loading ? (
                <Spinner />
              ) : (
                <div className="">
                  <div className="create-new-format-btn-area-wrap">
                    <button onClick={(e) => handleClick(e)}>
                      Create New Tag
                    </button>
                    <button>Cancel</button>
                  </div>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFormatBody;
