import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import icon1 from "./img/person.svg";
import profileImage from "./img/profile.png";
const TeacherEditBody = ({ author }) => {
  let history = useHistory();
  const handleSendToCoursePage = (e, courseId) => {
    e.preventDefault();
    history.push(`/course-details-page/${courseId}`);
  };
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Teacher</h3>
            <p>
              Teacher List<i className="bx bx-chevron-right"></i>
              <span> teacher Profile</span>{" "}
            </p>
          </div>
        </div>
        {/* <div className="packge-header-right">
          <Link to="/create-new-package">
            <span>+ </span>
            Create New
          </Link>
        </div> */}
      </div>
      <div className="package-main-content-list">
        <div className="profile-top-view">
          <div className="profile-image">
            <img src={profileImage} alt="" />
          </div>
          <div className="profile-content">
            <Table borderless>
              <thead>
                <tr>
                  <th>Teacher Name</th>
                  <th>Total Course</th>
                  <th>Total Category</th>
                  <th>Total Academic Area</th>
                  <th>Total Active TIme</th>
                  <th>Total Student</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{author.getSingleData?.authorName}</td>
                  <td>{author.totalCourse}</td>
                  <td>{author.totalCategory}</td>
                  <td>{author.totalAcademicArea}</td>
                  <td>10hrs</td>
                  <td>{author.totalStudents}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Row>
        <Col lg={4}>
          <div className="profile-general-information">
            <h4>General Information</h4>
            <div className="profile-user-info-wrap">
              <div className="user-info-single-item">
                <div className="user-info-item">
                  <span>
                    <img src={icon1} alt="" />
                  </span>
                  <span> User Role</span>
                </div>
                <div className="user-info-item2">
                  : <span>Author</span>
                </div>
              </div>{" "}
              {/* <div className="user-info-single-item">
                <div className="user-info-item">
                  <span>
                    <img src={icon2} alt="" />
                  </span>
                  <span> Mobile</span>
                </div>
                <div className="user-info-item2">
                  : <span>+88 0171777785</span>
                </div>
              </div>{" "}
              <div className="user-info-single-item">
                <div className="user-info-item">
                  <span>
                    <img src={icon3} alt="" />
                  </span>
                  <span> Email</span>
                </div>
                <div className="user-info-item2">
                  : <span>useremail@mail.com</span>
                </div>
              </div>{" "}
              <div className="user-info-single-item">
                <div className="user-info-item">
                  <span>
                    <img src={icon4} alt="" />
                  </span>
                  <span> Joined at</span>
                </div>
                <div className="user-info-item2">
                  : <span>5 May 2021</span>
                </div>
              </div> */}
            </div>
            {/* <div className="profile-user-info-wrap mt-5">
              <p>Payment Method</p>
              <div className="payment-method-wrapper-sdfcgvhbjn">
                <p>Credit Card</p>
              </div>
            </div>
            <div className="profile-user-info-wrap">
              <p>Package</p>
              <div className="payment-method-wrapper-sdfcgvhbjn">
                <p>Individual</p>
              </div>
            </div> */}
          </div>
        </Col>
        <Col lg={8}>
          <div className="profile-general-information">
            <h4>Course Information</h4>

            <div className="profile-user-info-wrap mt-5">
              <p>Course Category</p>
              <div className="payment-method-wrapper-sdfcgvhbjnhgvbj">
                <ul>
                  {author.getSingleData?.course.map((v, i) => (
                    <li>{v.courseName}</li>
                  ))}

                  {/* <li>Simulation</li> */}
                </ul>
              </div>
            </div>
            <div className="profile-user-info-wrap mt-4">
              <p>Academic Area</p>
              <div className="payment-method-wrapper-sdfcgvhbjnhgvbj">
                <ul>
                  {author.getSingleData?.academicArea?.map((v, i) => (
                    <li>{v.academicName}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="course-list-profile-page">
              <div className="dashboard-sidebar-single-item">
                <div className="sidebar-course-list">
                  {author.getSingleData?.course?.map((v, i) => (
                    <div className="course-sidebar-single-item ujhgfbsdvzbsd">
                      <div className="course-sidebar-single-item-left-sdfghcjv">
                        <img
                          height="50px"
                          width="80px"
                          src={`https://staging-courseware-backend.sgp1.digitaloceanspaces.com/${v?.image}`}
                          alt=""
                        />
                        <p>{v?.courseName}</p>
                      </div>
                      <div className="course-sidebar-single-item-right-sdfghcjv">
                        <span>Ongoing</span>
                        <button
                          onClick={(e) => handleSendToCoursePage(e, v._id)}
                        >
                          Overview
                        </button>
                      </div>
                    </div>
                  ))}{" "}
                </div>
              </div>{" "}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TeacherEditBody;
