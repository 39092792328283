import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert } from "reactstrap";
import {
  addToCart,
  addToGift,
  getSingleCourse,
  getSingleCourseOfValidUser,
  getSingleCourseOfValidUserSuccess,
  getSingleCourseSuccess,
  registerLogin,
  storeLoginUser,
} from "../../../store/actions";
import GiftReward from "../GiftReward/GiftReward";
import documetsIcon from "./img/document-text.svg";
import ForwardArrow from "./img/ForwardArrow";
import laptopIcon from "./img/laptop-svgrepo-com 2.svg";
import LockIconSee from "./img/lock.svg";
import modalImageItem from "./img/LoginModal.svg";
import saveIcon from "./img/saveCourse.svg";
import courseIcon1 from "./img/scan.svg";
import shopingBag from "./img/shopping-bag.svg";
import courseIcon2 from "./img/translate.svg";
const CourseDetails = () => {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const onOpenModal2 = () => setOpen2(true);
  const onCloseModal2 = () => setOpen2(false);

  const [lafargePopup, setLafargePopup] = useState(false);
  let dispatch = useDispatch();
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    role: "",
  });
  console.log(`CourseDetails ~ values`, values);
  // console.log(" lenght", JSON.parse(localStorage.getItem("cart"))?.length)

  const { token, course, owner, loading, role, loginLoading, error } =
    useSelector((state) => ({
      token: state.Login.token,
      course: state.AdminAuthorReducer.course,
      owner: state.AdminAuthorReducer.owner,
      loading: state.AdminAuthorReducer.loading,
      loginLoading: state.Login.loading,
      role: state.Login.userrole,
      error: state.Login.error,
    }));
  const { courseId } = useParams();
  useEffect(() => {
    if (token) {
      dispatch(getSingleCourseOfValidUser(token, courseId));
    } else {
      dispatch(getSingleCourse(courseId));
    }
    return () => {
      setLafargePopup(false);
      dispatch(getSingleCourseOfValidUserSuccess({}));
      dispatch(getSingleCourseSuccess({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (course?.slug) {
      if (token) {
        setLafargePopup(false);
      } else if (course?.slug != "basics-of-sales-management") {
        setLafargePopup(false);
      } else {
        setLafargePopup(true);
      }
    }
  }, [token, course?.slug]);

  const calculateDate = (date) => {
    var dateVar = moment(date);

    // var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    // var year = created_date.getFullYear();
    // var month = months[created_date.getMonth()];
    // var date = created_date.getDate();
    // var hour = created_date.getHours();
    // var min = created_date.getMinutes();
    // var sec = created_date.getSeconds();
    // var time = date + ',' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;    // final date with time, you can use this according your requirement

    return dateVar.utc().format("DD/MM/YYYY");
  };
  const handleAddToCart = (e) => {
    e.preventDefault();
    let cart = [];
    if (typeof window !== "undefined") {
      if (localStorage.getItem("cart")) {
        cart = JSON.parse(localStorage.getItem("cart"));
      }
      cart.push({
        ...course,
        count: 1,
      });
      let unique = _.uniqWith(cart, _.isEqual);
      // console.log("hello unique", unique);
      localStorage.setItem("cart", JSON.stringify(unique));
      toast.success("Course add into cart");
      dispatch(addToCart(unique));
    }
  };
  const showAnother = (courseId) => {
    let check = JSON.parse(localStorage.getItem("cart"))?.find(
      (el) => JSON.stringify(el._id) == JSON.stringify(courseId)
    );
    console.log("CHeck here", check);

    if (check) {
      return (
        <Button onClick={(e) => history.push("/cart-page")}>Go To Cart</Button>
      );
    } else {
      return <Button onClick={(e) => handleAddToCart(e)}> Add to Cart</Button>;
    }
    // return <>helllo</>
  };

  const handleGift = (e, email) => {
    e.preventDefault();
    console.log("email data", email);
    let gift = [];
    if (typeof window !== "undefined") {
      if (localStorage.getItem("gift")) {
        gift = JSON.parse(localStorage.getItem("gift"));
      }
      gift.push({
        ...course,
        count: 1,
      });
      let unique = _.uniqWith(gift, _.isEqual);
      console.log("hello unique", unique);
      localStorage.setItem("gift", JSON.stringify(unique));
      console.log("unique data here", unique);
      dispatch(addToGift(unique));
      history.push(`/checkout-gift?giftEmail=${email}`);
    }
  };

  const handleCopy = (e) => {
    e.preventDefault();
    let link = window.location.href;
    console.log("link data here", link);
    navigator.clipboard.writeText(`${link}`);
    toast.success("Link Coppied");
    // navigator.clipboard.writeText('Copy this text to clipboard')}
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      const emailValue = value?.split("@")?.[1];
      if (emailValue === "lafargeholcim.com") {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
    if (name === "confirmPassword") {
      if (value === values.password) {
        setPasswordError(false);
      } else {
        setPasswordError(true);
      }
    }
    if (name === "email") {
      setValues((prev) => ({ ...prev, [name]: value?.toLowerCase() }));
    } else {
      setValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!passwordError && !emailError) {
      dispatch(registerLogin(values, history, courseId, setLafargePopup));
    }
  };

  return (
    <div>
      <Modal
        open={lafargePopup}
        onClose={() => {
          setLafargePopup(false);
          dispatch(storeLoginUser("error", ""));
        }}
        classNames={{
          overlay: "lafargeOverlay",
          modal: "lafargeModal",
        }}
        center
        closeOnOverlayClick={false}
      >
        <div className="popup-modal-main-wrap">
          <div className="lafarge-registration-title">
            <h3>Registration</h3>
            <p>Register into the world of SIMULATION </p>
          </div>
          <div className="lafarge-reg-form-popup-wrap">
            <Form onSubmit={handleSubmit}>
              {error ? <Alert color="danger">{error}</Alert> : null}
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                  required
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="email"
                  style={{ textTransform: "lowercase" }}
                  className="form-control"
                  placeholder="example@lafargeholcim.com"
                  required
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
                {emailError && (
                  <div className="text-danger" style={{ textAlign: "left" }}>
                    Invalid email format
                  </div>
                )}
              </div>
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="phoneNumber"
                  className="form-control"
                  placeholder="Phone No."
                  required
                  value={values.phoneNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group mb-3">
                <Form.Select
                  required
                  value={values.role}
                  onChange={handleChange}
                  className="form-control"
                  name="role"
                  aria-label="Designation"
                >
                  <option value="">Select Designation</option>
                  <option value="rsm">RSM</option>
                  <option value="tsm">TSM</option>
                  <option value="asm">ASM</option>
                  <option value="kam">KAM</option>
                </Form.Select>
              </div>
              <div className="form-group mb-3">
                <input
                  type="password"
                  required
                  value={values.password}
                  onChange={handleChange}
                  name="password"
                  min="6"
                  className="form-control"
                  placeholder="New Password"
                />
              </div>
              <div className="form-group mb-3">
                <input
                  type="password"
                  min="6"
                  name="confirmPassword"
                  required
                  value={values.confirmPassword}
                  onChange={handleChange}
                  className="form-control"
                  placeholder="Confirm Password"
                />
                {passwordError && (
                  <div className="text-danger" style={{ textAlign: "left" }}>
                    Password not matched
                  </div>
                )}
              </div>
              <div className="registration-form-submit-button">
                <button type="submit" disabled={loginLoading}>
                  {loginLoading ? "Submitting..." : "Processed"}{" "}
                  <ForwardArrow />
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
      <Modal open={open} onClose={onCloseModal} center className="modal">
        <div className="popup-modal-main-wrap">
          <img src={modalImageItem} alt="" />
          <p>You need to sign in to proceed with payment.</p>
          <Link className="student-signup-btn-area" to="/login">
            Sign In
          </Link>
          <Link className="student-signup-btn-area-signup" to="/registration">
            Sign Up
          </Link>
        </div>
      </Modal>
      <Modal open={open2} onClose={onCloseModal2} center className="modal">
        <GiftReward handleGift={handleGift} course={course} />
      </Modal>
      <Container>
        <div className="details-page-inner-main-wrap">
          <Link onClick={() => history.goBack()} className="back-btn">
            <span>
              <i className="bx bx-arrow-back"></i>
            </span>
            <span>Back</span>
          </Link>
          <p>
            <i style={{ textTransform: "lowercase" }}>
              Home <i className="bx bx-chevron-right"></i>
              {course?.courseName}
            </i>
          </p>
          <Row>
            <Col lg={9}>
              <div className="course-details-content-main-area">
                <h2>{course?.courseName}</h2>
                <div className="course-share-btn-area">
                  <Link>
                    <span>
                      <img src={saveIcon} alt="" />
                    </span>
                    <span>Save</span>
                  </Link>
                  <Link onClick={(e) => handleCopy(e)}>
                    <span>
                      {/* <img src={shareIcon} alt="" /> */}
                      <i class="bx bx-copy"></i>
                    </span>
                    <span>Copy</span>
                  </Link>
                </div>
                <Row>
                  {/* <Col lg={4}>
                    <div className="course-details-single-box-item-wrap">
                      <i>IE CODE:</i>
                      <h4>
                        <img src={courseIcon1} alt="" />
                        <span>{course?.code}</span>
                      </h4>
                    </div>
                  </Col> */}
                  <Col lg={4}>
                    <div className="course-details-single-box-item-wrap">
                      <i>ACADEMIC AREA:</i>
                      <h4>
                        <span>
                          {course?.academicArea?.map((v, i) => (
                            <>
                              {v.academicName}
                              {course?.academicArea.length == i + 1 ? "" : "|"}
                            </>
                          ))}
                        </span>
                      </h4>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="course-details-single-box-item-wrap">
                      <i>FORMAT:</i>
                      <h4>
                        <img src={laptopIcon} alt="" />
                        <span>
                          Simulation
                          {/* {course?.category?.map((v, i) => (
                            <>
                              {v.categoryName}
                              {course?.category.length == i + 1 ? "" : "|"}
                            </>
                          ))} */}
                        </span>
                      </h4>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="course-details-single-box-item-wrap">
                      <i>LANGUAGE:</i>
                      <h4>
                        <img src={courseIcon2} alt="" />
                        <span>{course?.language}</span>
                      </h4>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="course-details-single-box-item-wrap">
                      <i>NR. OF PAGES:</i>
                      <h4>
                        <img src={courseIcon1} alt="" />
                        <span>{course?.noOfPages}</span>
                      </h4>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="course-details-single-box-item-wrap">
                      <i>TYPE OF PUBLICATION:</i>
                      <h4>
                        <img src={documetsIcon} alt="" />
                        <span>
                          Case Study
                          {/* {course?.typeOfPublication} */}
                        </span>
                      </h4>
                    </div>
                  </Col>{" "}
                </Row>
                <>
                  {token && owner == true ? (
                    <div className="course-more-btn-area-see-more-btn">
                      <Link
                        to={
                          course?.slug === "basics-of-sales-management"
                            ? `/${course?._id}/lafarge`
                            : `/${course._id}/game-start?courseName=${course?.slug}`
                        }
                      >
                        <button>
                          {/* <button onClick={onOpenModal}> */}
                          {/* <img src={LockIconSee} alt="" /> */}
                          Start
                        </button>
                      </Link>
                      <button>
                        <a
                          href="https://docs.google.com/document/d/10dHd1y3KZDKPM5-Ee-gvTAiA5FUblH4G/edit"
                          target="_blank"
                          rel="noreferrer"
                        >
                          See Instruction
                        </a>
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                  {token && owner == true && role == "teacher" ? (
                    <div className="course-more-btn-area-see-more-btn">
                      <Link to={`/${course._id}/teacher-add-course-page`}>
                        <button>
                          {/* <button onClick={onOpenModal}> */}
                          <img src={LockIconSee} alt="" /> View Students
                        </button>
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
                <div className="content-decription-coursware">
                  <i>DESCRIPTION</i>
                  <p>
                    {course?.description}
                    <br />
                    <br />
                    At the end of the note, the author includes a practical
                    example of financial restructuring in a food processing
                    company.
                  </p>
                </div>
                {/* <div className="row">
                  <div className="col-lg-6">
                    <Card className="card-main-area-course-page-main-card">
                      <Card.Img variant="left" src={recorderIcon} />
                      <Card.Body>
                        <Card.Title>
                          <h3>Educator Copy</h3>
                        </Card.Title>
                        <Card.Text>
                          <i>Access a full preview copy of the article.</i>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div> */}
              </div>
            </Col>
            <Col lg={3}>
              <aside className="course-author-main-area-kmfskm">
                {course?.slug != "basics-of-sales-management" ? (
                  <>
                    <div className="course-price-wrap-jdkbad">
                      <i>Price Per Unit:</i>
                      <h1>TK. {course?.price}</h1>
                    </div>
                    {!loading && owner == false && (
                      <>
                        <div className="buy-course-main-wrap">
                          <img src={shopingBag} alt="" />
                          {showAnother(course._id)}
                        </div>
                      </>
                    )}
                    {token && (
                      <div className="course-gift-area-main-wrap">
                        {/* <button onClick={onOpenModal}>Open modal</button> */}
                        <div className="gift-main-button">
                          <Link onClick={onOpenModal2}>Gift & Reward</Link>
                        </div>
                      </div>
                    )}
                  </>
                ) : null}

                <div className="course-author-main-area-wrap-jbd">
                  <i>THE AUTHORS</i>
                  <hr />
                  <img
                    src={`${process.env.REACT_APP_IMAGE_URL}${course?.author?.authorPhoto}`}
                    height="75px"
                    alt=""
                  />
                  <h5>{course?.author?.authorName}</h5>
                  <i>ACADEMIC AREA:</i>
                  <h6>
                    <span>
                      {course?.author?.academicArea?.map((v, i) => (
                        <>
                          {v.academicName}
                          {course?.author?.academicArea?.length == i + 1
                            ? ""
                            : "|"}
                        </>
                      ))}
                    </span>
                  </h6>
                  {/* <div className="buy-course-main-wrap fdgf">
                    <button
                      onClick={(e) =>
                        history.push(`/${course?.author?._id}/author-profile`)
                      }
                    >
                      Read More
                    </button>
                  </div> */}
                </div>
                <div className="course-author-main-area-wrap-jbd gsfgf">
                  <i>ADDITIONAL INFO</i>
                  <hr />

                  <div className="additional-info-main-area-hbgjfkdl">
                    <div className="gfdbvghnhjn">
                      <p>
                        <b>LAST UPDATED:</b>
                      </p>
                      <i>{calculateDate(course?.updatedAt)}</i>
                      <hr />
                    </div>
                    <div className="gfdbvghnhjn">
                      <p>
                        <b>Created At:</b>
                      </p>
                      <i>{calculateDate(course?.createdAt)}</i>
                      <hr />
                    </div>
                    <div className="gfdbvghnhjn">
                      <p>
                        <b>SUBJECTS COVERED:</b>
                      </p>
                      <i>{course?.subjectCoverd}</i>
                    </div>
                  </div>
                </div>
              </aside>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default CourseDetails;
