import { Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { logoutUser } from "../../../store/actions";
import logo from "../img/logo.svg";
import profileImage from "../img/profile-image.png";
const DashboardCoomonTopbar = () => {
  const { token, cart, username, firstname, email, role } = useSelector(
    (state) => ({
      token: state.Login.token,
      username: state.Login.username,
      role: state.Login.userrole,
      firstname: state.Login.firstname,
      email: state.Login.email,
      cart: state.cartReducer.cart,
    })
  );
  let nameData = email?.split("@")[0];

  const handleLogout = () => {
    dispatch(logoutUser(history));
  };

  let dispatch = useDispatch();
  let history = useHistory();
  return (
    <div className="dashboard-topbar-main-wrap">
      <div className="dashboard-topbar-items-wrap">
        <div className="dashboard-topbar-logo">
          <img src={logo} alt="" />
        </div>
        <div className="dashboard-topbar-right-content">
          <div className="dashboard-right-side-profile-item">
            <div className="dashboard-main-profile-wrap-language">
              <span className="dashboard-topbar-language-globe-icon">
                <i className="bx bx-globe"></i>
              </span>
              <Form.Select aria-label="Language">
                <option>EN</option>
                <option value="1">BN</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </div>
            <div className="dashboard-main-profile-wrap-notifications">
              <span>
                <i className="bx bx-bell"></i>
              </span>
            </div>{" "}
            <div className="dashboard-main-profile-wrap-admin">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-coustom-design-class-fghdnjmk"
                >
                  <img src={profileImage} alt="" />
                  {nameData}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item className="nav-item">
                    <NavLink className="nav-link" to="/profile">
                      Profile
                    </NavLink>
                  </Dropdown.Item> */}
                  {role == "admin" && (
                    <Dropdown.Item className="nav-item">
                      <NavLink className="nav-link" to="/admindashboard">
                        Dashboard
                      </NavLink>
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item>
                    <div className="dropdown-divider" />
                    <span className="dropdown-item">
                      <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                      <span
                        className="user-select-auto"
                        onClick={() => handleLogout()}
                      >
                        Logout
                      </span>
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCoomonTopbar;
