import React, {useEffect, useState} from "react";
import { Form, Table } from "react-bootstrap";
import { Link ,useHistory, useLocation, useParams} from "react-router-dom";
import deleteIcon from "../../img/close-circle.svg";
import editIcon from "../../img/edit-2.svg";
import { FormatData } from "./FormatData";
// import {  } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCoupons, deleteCoupon } from './../../../../store/actions';
import moment from "moment";
import { toast } from "react-toastify";
const CouponBody = () =>
{
  let history = useHistory();
  let dispatch = useDispatch();
 

  const convertDateToLocal = dateLocal =>
  {
    let date = moment.utc().format('YYYY-MM-DD HH:mm:ss');

  console.log(date); // 2015-09-13 03:39:27
  
  let stillUtc = moment.utc(dateLocal).toDate();
  return moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
  }
 
  const { token, loading, coupon } = useSelector((state) => ({
    token: state.Login.token,
    loading: state.AdminAuthorReducer.loading,
    coupon : state.adminDashboardReducer.coupons

  }));

  const handleDelete = (e, couponId) =>{
    e.preventDefault()
     if (!couponId) {
      toast.error('Coupon Id is not valid', {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    } else {
      dispatch(deleteCoupon(token, couponId))
  }
  }
  useEffect(() =>
  {
    dispatch(getCoupons(token))
  },[])
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Coupon List</h3>
            <p>
              Coupon list{`>`} <span>Quiz List</span>{" "}
            </p>
          </div>
        </div>
        <div className="packge-header-right">
          <Link to="/coupon-new">
            <span>+ </span>
            Create New
          </Link>
        </div>
      </div>
      <div className="data-search-and-filtering-area-wrap">
        <div className="data-search-inner-area-jhhbcvhkjzjghfjdn">
          <div className="search-box-dfghjkiujfb">
            <input type="search" name="" id="" />
          </div>
          <div className="select-filtering-area-jyfgvasjyhdfb">
            <Form.Group>
              <Form.Select>
                <option value="">Select name</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="package-main-content-list">
        <Table borderless>
          <thead className="package-main-content-list-table-header">
            <tr>
              <th>#</th>
              <th>Coupon Code</th>
              <th>Coupon Percentage</th>
              <th>Start Date</th>
              <th>Ending Date</th>
              <th>Action</th>
            </tr>
          </thead>
           <tbody>
            {coupon?.map((item, i) =>
            {
              
              return (
                <tr key={i}>
                {/* {JSON.stringify(i)} */}
                  <td>{ i+1}</td>
                  <td>{item?.couponCode}</td>
                  <td>{item?.couponPercentage}</td>
                  <td>{convertDateToLocal(item?.couponStartingDate)}</td>
                  <td>{convertDateToLocal(item?.couponEndingDate)}</td>
                  {/* <td>{item.TotalQuestion}</td> */}
                  <td>
                    <div className="package-control-action-area">
                      <Link to="coupon-edit">
                        <img src={editIcon} alt="" /> Edit
                      </Link>
                      <button onClick={e => handleDelete(e, item._id) }>
                        <img src={deleteIcon} alt="" />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default CouponBody;
