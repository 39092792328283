import axios from "axios";

const REACT_APP_API = `https://${process.env.REACT_APP_API}`;
// const REACT_APP_API = `https://courseware-backend.calibrax.org/api`;
// const REACT_APP_API = "http://localhost:5000/api";
//user account managemnet

export const axiosApi = axios.create({
  baseURL: REACT_APP_API,
});
//  axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("GLOBAL_ERROR", error.response);
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, data, { ...config })
    .then((response) => response.data);
}

export async function patch(url, data, config) {
  return axiosApi
    .patch(url, data, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export const axiosUserLogin = async (email, password) =>
  await axios.post(`${REACT_APP_API}/login`, { email, password });
//Teacher Registration
export const axiosTeacherRegistration = async (
  role,
  firstName,
  lastName,
  email,
  phoneNumber,
  instituteName,
  password,
  confirmPassword
) =>
  await axios.post(`${REACT_APP_API}/registration-teacher`, {
    role,
    firstName,
    lastName,
    email,
    phoneNumber,

    instituteName,
    password,
    confirmPassword,
  });

//Student Registration
export const axiosStudentRegistration = async (
  role,
  firstName,
  lastName,
  email,
  phoneNumber,
  instituteName,
  password,
  confirmPassword
) =>
  await axios.post(`${REACT_APP_API}/registration-student`, {
    role,
    firstName,
    lastName,
    email,
    phoneNumber,
    instituteName,
    password,
    confirmPassword,
  });

//reset password
export const axiosResetPassword = async (
  accountActivateToken,
  email,
  password,
  confirmPassword
) =>
  await axios.patch(`${REACT_APP_API}/user/account-verify`, {
    accountActivateToken,
    email,
    password,
    confirmPassword,
  });

/* --------------------- Admin Panel ------------- */

//admin page
//get number of data
export const axiosGetNumberDataAdminPage = async (authtoken) =>
  await axios.get(
    `${REACT_APP_API}/admin/get-numbers-of-teachers-students-categories-academicareas-courses`,
    {
      headers: { authtoken },
    }
  );
//get Recent Courses
export const axiosGetRecentCourses = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/get-recent-courses`, {
    headers: { authtoken },
  });

//get popular courses
export const axiosGetPopularCourses = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/get-top-five-course`, {
    headers: { authtoken },
  });

//get categories
export const axiosGetCategories = async () =>
  await axios.get(`${REACT_APP_API}/read-all-category`, {});

//get enrolment data
export const axiosGetStudentEnrolmentData = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/admin/get-student-enrolment-data`, {
    headers: { authtoken },
  });

/* --------------------- END ------------- */

/* -------------------- Author Page ------------ */
//get List Of Authors
export const axiosGetAllAuthor = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/read-all-author`, {
    headers: { authtoken },
  });

//get single author
export const axiosGetSingleAuthor = async (authorId, authtoken) =>
  await axios.get(`${REACT_APP_API}/get-selected-author-data/${authorId}`, {
    headers: { authtoken },
  });
//update author
export const axiosUpdateAuthor = async (
  authtoken,
  authorName,
  phoneNumber,
  email,
  academicIds,
  description,
  photo,
  formatIds,
  authorId
) =>
  await axios.patch(
    `${REACT_APP_API}/update-single-author/${authorId}`,
    {
      authorName,
      phoneNumber,
      email,
      academicIds,
      description,
      photo,
      formatIds,
    },
    { headers: { authtoken } }
  );

//create author
export const axiosCreateAuthor = async (
  authtoken,
  authorName,
  email,
  phoneNumber,
  academicIds,
  formatIds,
  photo,
  description
) =>
  await axios.post(
    `${REACT_APP_API}/create-author`,
    {
      authorName,
      email,
      phoneNumber,
      academicIds,
      formatIds,
      photo,
      description,
    },
    { headers: { authtoken } }
  );

/* -------------------- END ------------ */

/* -------------------- Category Page ------------ */
//creat category
export const axiosCreateCategory = async (authtoken, formData) =>
  await axios.post(`${REACT_APP_API}/create-category`, formData, {
    headers: { authtoken },
  });
//get category ids
export const axiosGetCategoryIds = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/get-category-ids`, {
    headers: { authtoken },
  });
/* -------------------- END ------------ */

/* -------------------- Students Admin Page ------------ */
//get Students
export const axiosGetStudents = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/get-all-students`, {
    headers: { authtoken },
  });

//get single student
export const axiosGetSingleStudent = async (authtoken, id) =>
  await axios.get(`${REACT_APP_API}/get-single-student/${id}`, {
    headers: { authtoken },
  });

/* -------------------- END ------------ */

/* --------------------  Academic Areas ------------ */
//get Students
export const axiosGetAcademicAreas = async () =>
  await axios.get(`${REACT_APP_API}/read-all-academic-areas`);
export const axiosCreateAcademicArea = async (
  authtoken,
  academicName,
  categoryIds
) =>
  await axios.post(
    `${REACT_APP_API}/create-academic-areas`,
    { academicName, categoryIds },
    { headers: { authtoken } }
  );

export const axiosGetCourseByAcademy = async (academicId) =>
  await axios.get(`${REACT_APP_API}/get-all-product-of/${academicId}`);
/* -------------------- END ------------ */

/* --------------------  Multiselctor ------------ */
//get Students
export const axiosGetMultiSelectorData = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/read-get-multiselector-data`, {
    headers: { authtoken },
  });

//course related
export const axiosCourseRelatedMultiData = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/get-course-related-multi-data`, {
    headers: { authtoken },
  });
/* -------------------- END ------------ */
/* --------------------  Course ------------ */
//Create Course
export const axiosCreateCourse = async (
  authtoken,
  courseName,
  code,
  noOfPages,
  category,
  price,
  estimatedTime,
  description,
  subjectCoverd,
  language,
  academicArea,
  author,
  typeOfPublication,
  duration,
  coursePhoto
) =>
  await axios.post(
    `${REACT_APP_API}/create-course`,
    {
      courseName,
      code,
      noOfPages,
      category,
      price,
      estimatedTime,
      description,
      subjectCoverd,
      language,
      academicArea,
      author,
      typeOfPublication,
      duration,
      coursePhoto,
    },
    { headers: { authtoken } }
  );

export const axiosGetCourses = async () =>
  await axios.get(`${REACT_APP_API}/get-all-course`);

export const axiosGetSingleCourse = async (courseId) =>
  await axios.get(`${REACT_APP_API}/read-single-course/${courseId}`);

//get top six course
export const axiosGetSixCourses = async () =>
  await axios.get(`${REACT_APP_API}/get-six-courses`);

export const axiosGetAllCourses = async () =>
  await axios.get(`${REACT_APP_API}/get-all-course`);

export const axiosGetDataOnSearch = async (text) =>
  await axios.post(`${REACT_APP_API}/get-data-on-query`, { text });

//get single course of valid user
export const axiosGetSingleCourseOfValidUser = async (authtoken, courseId) =>
  await axios.get(`${REACT_APP_API}/get-single-valid-course/${courseId}`, {
    headers: { authtoken },
  });
/* -------------------- END ------------ */

/* --------------------  Order ------------ */
//Create Order
export const axiosCreateOrder = async (
  course,
  price,
  totalPrice,
  email,
  mainPrice,
  discount,
  authtoken
) =>
  await axios.post(
    `${REACT_APP_API}/ssl-request`,
    { course, price, totalPrice, email, mainPrice, discount },
    { headers: { authtoken } }
  );
/* -------------------- END ------------ */

/* ------ OwnCourse ----- */
export const axiosGetStudentOwnCourse = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/get-student-own-course`, {
    headers: { authtoken },
  });

//submit mark
export const axiosSubmitMark = async (authtoken, courseId, mark, orderId) =>
  await axios.patch(
    `${REACT_APP_API}/submit-mark/${courseId}`,
    { mark, orderId },
    { headers: { authtoken } }
  );
export const axiosShowResult = async (authtoken, courseId) =>
  await axios.get(`${REACT_APP_API}/show-result/${courseId}`, {
    headers: { authtoken },
  });

//course owner valid

export const axiosCourseOwnerValid = async (authtoken, courseId) =>
  await axios.get(`${REACT_APP_API}/course-owned-by-user/${courseId}`, {
    headers: { authtoken },
  });

//get ranking based on course
export const axiosGetRankingBasedOnCourse = async (authtoken, role, courseId) =>
  await axios.get(
    `${REACT_APP_API}/get-ranking-based-on-course/${courseId}/${role}`,
    { headers: { authtoken } }
  );

export const axiosGetRankingBasedOnCourseByTeacher = async (
  authtoken,
  courseId
) =>
  await axios.get(
    `${REACT_APP_API}/get-ranking-based-on-course-by-teacher/${courseId}`,
    { headers: { authtoken } }
  );

export const axiosViewStudents = async (authtoken, courseId) =>
  await axios.get(`${REACT_APP_API}/view-students/${courseId}`, {
    headers: { authtoken },
  });

export const axiosInsertStudentIntoCourse = async (
  authtoken,
  courseId,
  email
) =>
  await axios.patch(
    `${REACT_APP_API}/insert-student-into-course`,
    { courseId, email },
    { headers: { authtoken } }
  );

//coupon related access only for admin

export const axiosCreateCoupon = async (
  authtoken,
  couponCode,
  couponPercentage,
  couponStartingDate,
  couponEndingDate
) =>
  await axios.post(
    `${REACT_APP_API}/create-coupon`,
    { couponCode, couponPercentage, couponStartingDate, couponEndingDate },
    { headers: { authtoken } }
  );

export const axiosGetAllCouponForAdmin = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/get-all-coupon-for-admin`, {
    headers: { authtoken },
  });

export const axiosGetAllCouponForStudent = async () =>
  await axios.get(`${REACT_APP_API}/get-all-coupon-for-other`);
export const axiosDeleteCoupon = async (authtoken, couponId) =>
  await axios.delete(`${REACT_APP_API}/delete-coupon/${couponId}`, {
    headers: { authtoken },
  });

export const axiosUpdateCoupon = async (
  authtoken,
  couponId,
  couponStartingDate,
  couponEndingDate,
  couponPercentage
) =>
  await axios.patch(
    `${REACT_APP_API}/update-coupon/${couponId}`,
    { couponStartingDate, couponEndingDate, couponPercentage },
    { headers: { authtoken } }
  );

//forget password
export const axiosForgetPassword = async (email) =>
  await axios.patch(`${REACT_APP_API}/forget-password`, { email });

//update profile
export const axiosUpdateProfile = async (
  authtoken,
  firstName,
  lastName,
  phoneNumber
) =>
  await axios.patch(
    `${REACT_APP_API}/update-profile`,
    { firstName, lastName, phoneNumber },
    { headers: { authtoken } }
  );

//get me
export const axiosGetMe = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/get-me`, { headers: { authtoken } });

//get coupon for user
export const axiosGetCouponByUser = async () =>
  await axios.get(`${REACT_APP_API}/get-coupon-by-student`);

//update password
export const axiosUpdatePassword = async (
  authtoken,
  currentPassword,
  password,
  confirmPassword
) =>
  await axios.patch(
    `${REACT_APP_API}/update-password`,
    { currentPassword, password, confirmPassword },
    { headers: { authtoken } }
  );

//search on category
export const axiosSearchOnCategory = async (categoryId) =>
  await axios.get(`${REACT_APP_API}/get-data-on-category/${categoryId}`);

//remove students from course
export const axiosRemoveStudentFromCourse = async (
  authtoken,
  courseId,
  studentId
) =>
  await axios.patch(
    `${REACT_APP_API}/remove-student-from-course`,
    { courseId, studentId },
    { headers: { authtoken } }
  );
// await axios.patch(`${REACT_APP_API}/remove-student-from-course`, { }, { headers: authtoken })
export const axiosGetAuthorIDData = async (authorId) =>
  await axios.get(`${REACT_APP_API}/get-selected-author-data/${authorId}`);

export const axiosGetCourseDataForUpdate = async (authtoken, courseId) =>
  await axios.get(`${REACT_APP_API}/get-course-data-for-update/${courseId}`, {
    headers: { authtoken },
  });
export const axiosUploadAuthorPhoto = async (authtoken, formData) =>
  await axios.patch(`${REACT_APP_API}/upload-image`, formData, {
    headers: { authtoken },
  });

export const axiosUploadCoursePhoto = async (authtoken, formData) =>
  await axios.patch(`${REACT_APP_API}/upload-course-image`, formData, {
    headers: { authtoken },
  });
export const axiosGetFormat = async (authtoken, categoryId) =>
  await axios.get(`${REACT_APP_API}/read-single-category/${categoryId}`, {
    headers: { authtoken },
  });

export const axiosAcademicArea = async (authtoken, academicId) =>
  await axios.get(`${REACT_APP_API}/get-single-academic-area/${academicId}`, {
    headers: { authtoken },
  });

export const axiosUpdateAcademicArea = async (
  authtoken,
  academicId,
  academicName
) =>
  await axios.patch(
    `${REACT_APP_API}/update-single-academic-area/${academicId}`,
    { academicName },
    { headers: { authtoken } }
  );

export const axiosUpdateFormat = async (
  authtoken,
  formatId,
  formatName,
  formatPhoto
) =>
  await axios.patch(
    `${REACT_APP_API}/update-format/${formatId}`,
    { formatName, formatPhoto },
    { headers: { authtoken } }
  );

export const axiosUpdateCourse = async (
  authtoken,
  courseId,
  courseName,
  courseCode,
  noOfPages,
  estimatedTime,
  coursePrice,
  description,
  coursePhoto,
  language,
  subjectCoverd,
  validation,
  typeOfPublication
) =>
  await axios.patch(
    `${REACT_APP_API}/update-course-by-admin/${courseId}`,
    {
      courseName,
      courseCode,
      noOfPages,
      estimatedTime,
      coursePrice,
      description,
      coursePhoto,
      language,
      subjectCoverd,
      validation,
      typeOfPublication,
    },
    { headers: { authtoken } }
  );

export const axiosAccountVerifyUser = async (email, accountActivateToken) =>
  await axios.patch(`${REACT_APP_API}/account-verify`, {
    email,
    accountActivateToken,
  });

//lafarge api

//get lafarge course
export const axiosGetLafargeCourse = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/admin/view-lafarze-course`, {
    headers: { authtoken },
  });

//get lafarge user
export const axiosGetUser = async (authtoken, skip) =>
  await axios.get(
    `${REACT_APP_API}/admin/lafarz/view-users/table?skip=${skip}
`,
    {
      headers: { authtoken },
    }
  );

//get lafarge total user
export const axiosGetTotalUser = async (authtoken) =>
  await axios.get(`${REACT_APP_API}/admin/view-users`, {
    headers: { authtoken },
  });

//get lafarge course
export const axiosStudentAnswer = async (authtoken, studentId) =>
  await axios.get(
    `${REACT_APP_API}/admin/lafarz/view-answer-student/${studentId}`,
    {
      headers: { authtoken },
    }
  );

//update total marks
export const axiosUpdateTotalMarks = async (authtoken, examId, mark) =>
  await axios.patch(
    `${REACT_APP_API}/admin/lafarz/submit-mark/${examId}`,
    { mark },
    { headers: { authtoken } }
  );

//get orders realted
export const axiosGetOrdersRelatedData = async () =>
  await axios.get(`${REACT_APP_API}/get-Order-data`);
