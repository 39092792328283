import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { createAcademicArea, getCategoryIds } from "../../../../store/actions";
const EditCouponBody = () => {
  let history = useHistory();
  let dispatch = useDispatch();
  let [category, setCategory] = useState([]);
  let [academicName, setAcademicName] = useState("");
  const { token, loading, categoryIds } = useSelector((state) => ({
    token: state.Login.token,
    loading: state.AdminAuthorReducer.loading,
    categoryIds: state.AdminAuthorReducer.categoryIds,
  }));

  useEffect(() => {
    dispatch(getCategoryIds(token));
  }, []);

  const handleSubmitData = (e) => {
    e.preventDefault();
    console.log("hello data", category, academicName);

    if (!category.length || !academicName) {
      toast.error("Please fill all the field first");
    } else {
      let categoryArr = [];
      category.map((v, i) => {
        categoryArr.push(v.value);
      });

      dispatch(createAcademicArea(token, academicName, categoryArr, history));
    }
  };

  //store category

  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Edit Coupon</h3>
            <p>
              Course list <i className="bx bx-chevron-right"></i> coupon area{" "}
              <i className="bx bx-chevron-right"></i>
              <span> Edit coupon Area</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="package-main-content-list kjhgfwertyuiutfc">
        <div className="add-new-format-area-wrap">
          <div className="add-new-format-inner-item-wrap">
            <h4>Edit coupon Area</h4>
            <Form className="format-upload-form-main-area-zkjdh">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Coupon Code</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Coupon Code"
                  value={academicName}
                  onChange={(e) => setAcademicName(e.target.value)}
                />
              </Form.Group>{" "}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Coupon Percentage</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Coupon Code"
                  value={academicName}
                  onChange={(e) => setAcademicName(e.target.value)}
                />
              </Form.Group>{" "}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Starting date </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Tag name"
                  value={academicName}
                  onChange={(e) => setAcademicName(e.target.value)}
                />
              </Form.Group>{" "}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Ending date </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Tag name"
                  value={academicName}
                  onChange={(e) => setAcademicName(e.target.value)}
                />
              </Form.Group>
              <Row>
                <Col sm={12}>
                  <div className="error-main-area-hdfskjdfbschdb">
                    <p>
                      <i className="bx bxs-error-alt"></i> Error
                    </p>
                  </div>
                </Col>{" "}
              </Row>
              <div className="">
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="create-new-format-btn-area-wrap">
                    <button onClick={(e) => handleSubmitData(e)}>
                      Create New Coupon
                    </button>
                    <button>Cancel</button>
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCouponBody;
