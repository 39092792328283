import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import deleteIcon from "../../img/close-circle.svg";
import editIcon from "../../img/edit-2.svg";
import { packageData } from "./PackageData";
const PackageBody = () => {
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Package List</h3>
            <p>
              Course > <span>Category List</span>{" "}
            </p>
          </div>
        </div>
        <div className="packge-header-right">
          <Link to="/create-new-package">
            <span>+ </span>
            Create New
          </Link>
        </div>
      </div>
      <div className="package-main-content-list">
        <Table borderless>
          <thead className="package-main-content-list-table-header">
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Package Type</th>
              <th>Cost Type </th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {packageData.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.packageType}</td>
                  <td>{item.costType}</td>
                  <td>{item.status}</td>
                  <td>
                    <div className="package-control-action-area">
                      <Link
                        to={
                          item.name === "Gift & Rewards" ? "reward" : item.name
                        }
                      >
                        <img src={editIcon} alt="" /> Edit
                      </Link>
                      <button>
                        <img src={deleteIcon} alt="" />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default PackageBody;
