import { Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import deleteIcon from "../../img/close-circle.svg";
import editIcon from "../../img/edit-2.svg";
const AcademicistBody = ({ academicAreas, loading }) => {
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Academic Area List</h3>
            <p>
              Course list {`>`} <span>Academic Area List</span>{" "}
            </p>
          </div>
        </div>
        <div className="packge-header-right">
          <Link to="/create-academic-new">
            <span>+ </span>
            Create New
          </Link>
        </div>
      </div>
      <div className="data-search-and-filtering-area-wrap">
        <div className="data-search-inner-area-jhhbcvhkjzjghfjdn">
          <div className="search-box-dfghjkiujfb">
            <input type="search" name="" id="" />
          </div>
          <div className="select-filtering-area-jyfgvasjyhdfb">
            <Form.Group>
              <Form.Select>
                <option value="">Select name</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="package-main-content-list">
        <Table borderless>
          <thead className="package-main-content-list-table-header">
            <tr>
              <th>#</th>
              <th>Academic Area Name</th>
              <th>Category Name</th>
              {/* <th>Course Count </th> */}
              {/* <th>Slug</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading && <Spinner />}
            {!loading &&
              academicAreas.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.academicName}</td>
                    <td>
                      {item.category.map((v, i) => (
                        <>
                          {v.categoryName}{" "}
                          {item?.category?.length - 1 == i ? "" : " | "}
                        </>
                      ))}
                    </td>
                    {/* <td>{item.CourseCount}</td> */}
                    {/* <td>{item.Slug}</td> */}
                    <td>
                      <div className="package-control-action-area">
                        <Link to={`/academic-edit/${item._id}`}>
                          <img src={editIcon} alt="" /> Edit
                        </Link>
                        <button>
                          <img src={deleteIcon} alt="" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AcademicistBody;
